import React from "react";
import styles from "./sitename.module.scss";

import PropTypes from "prop-types";
import logoAllas from "../../assets/logoAllas.png";
import logoElle from "../../assets/logoElle.png";
import logoFemina from "../../assets/logoFemina.png";
import logoFrida from "../../assets/logoFrida.png";
import logoAller from "../../assets/logoAller.png";
import logoHant from "../../assets/logoHant.png";
import logoMabra from "../../assets/logoMabra.png";
import logoMotherhood from "../../assets/logoMotherhood.png";
import logoResidence from "../../assets/logoResidence.png";
import logoSvenskdam from "../../assets/logoSvenskdam.png";
import logoBaaam from "../../assets/logoBaaam.png";
import logoRecept from "../../assets/logoRecept.png";
import logoSeiska from "../../assets/logoSeiska.svg";
import logoFamiliejournal from "../../assets/logoFamiliejournal.png";
const logotypes = {
    allas: logoAllas,
    elle: logoElle,
    femina: logoFemina,
    frida: logoFrida,
    baaam: logoBaaam,
    global: logoAller,
    hant: logoHant,
    mabra: logoMabra,
    motherhood: logoMotherhood,
    residencemagazine: logoResidence,
    svenskdam: logoSvenskdam,
    recept: logoRecept,
    seiska: logoSeiska,
    familiejournal: logoFamiliejournal,
    
};

const resolveLogotypeByGamSiteId = gamSiteId => {
    const part = gamSiteId.split('.')
    const name = part.length === 2 ? part[0] : gamSiteId;
    return logotypes[name.toLowerCase()] || undefined;
}

const capitalizeFirst = str => str.charAt(0).toUpperCase() + str.slice(1)

const showBg = gamSiteId => gamSiteId.includes('recept')

const SiteName = props => (
    <h2 className={showBg(props.gamSiteId) ? styles.sitename__header_bg : styles.sitename__header }>
        { !resolveLogotypeByGamSiteId(props.gamSiteId) && capitalizeFirst(props.gamSiteId) }
        <img src={resolveLogotypeByGamSiteId(props.gamSiteId)} alt="" />
    </h2>
);

SiteName.propTypes = {
    name: PropTypes.string.isRequired,
    gamSiteId: PropTypes.string.isRequired
};

export default SiteName;